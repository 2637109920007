.font-link {
  font-family: "Lato", sans-serif;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Lato", sans-serif !important;
}
p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

h4,
h5,
h6 {
  font-size: 18px !important;
}
