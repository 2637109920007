.product-hero-sub-cont {
  display: flex;
  align-items: flex-end;
}
.product-hero-cont {
  min-width: 1200px;
}
.product-hero-left {
  width: 50vw;
}
.product-hero-right {
  width: 50vw;
}

.product-carousel-caption {
  position: absolute;
  right: 0% !important;
  bottom: 0 !important;
  left: 0% !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #fff;
  text-align: center;
}

.product-banner-title {
  font-size: 56px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: left;
  margin-left: 165px;
  line-height: 55px;
  padding-top: 45px;
  padding-bottom: 3px;
  background: linear-gradient(99.82deg, #41b2d2 4.25%, #f0047f 84.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.product-banner-subtitle {
  font-size: 28px;
  color: black;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  margin-left: 165px;
  letter-spacing: 5px;
}

.product-hero1 {
  background-image: url("../../Assets/hero-cont.png");
  height: 90vh;
  background-position: top;
  background-size: cover;
}

.product-hero2 {
  background-image: url("../../Assets/hero-cont2.png");
  height: 90vh;
  background-position: center;
  background-size: cover;
}
.product-hero3 {
  background-image: url("../../Assets/hero-cont3.png");
  height: 90vh;
  background-position: center;
  background-size: cover;
}

#product-img-hero {
  height: 330px !important;
  width: 50vw !important;
}
.product-banner {
  height: 240px;
  width: 585px;
  background: url("../../Assets/banner-back-product-desk.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 250px;
}

.product-banner-title {
  font-size: 45px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: left;
  margin-left: 165px;
  padding-top: 40px;
  background: linear-gradient(99.82deg, #41b2d2 4.25%, #f0047f 84.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.product-cont-categories {
  padding-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
  max-width: 1240px;
  min-width: 1000px;
}
.product-box-cont-categories {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.product-box-category {
  width: 500px;
  height: 500px;
  border: solid #d9d9d9 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 45px;
}
.product-title-categories {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #313131;
  padding-bottom: 40px;
}
/* Microondas Digital 20 Litros */
.product-name {
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #313131;
}

.product-subtitle {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #313131;
  margin-right: 100px;
  margin-left: 100px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.cta-product {
  font-size: 14px;
  margin-left: 25px;
  background-color: #3c5ea9;
  color: white !important;
  padding: 10px 70px;
  text-decoration: none !important;
  margin-bottom: 30px;
}

.product-hero {
  margin-top: 100px;
  margin-bottom: 235px;
  /* margin-left: 100px; */
  /* margin-right: 100px; */
  max-width: 1240px;
  min-width: 1000px;
  /* background-color: red; */
  height: 430px;
}

.i-text-prod {
  font-weight: 300;
  font-size: 33px;
  line-height: 48px;
  color: #313131;
}

.i-text-prod-mob {
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #313131;
  margin-bottom: 60px;
}

/* Más que un microondas, una experiencia */

.hero-products {
  background-image: url("../../Assets/hero-cont.png");
  /* height: 61vh; */
  height: 450px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.hero-products-mob {
  height: 74vh;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 30px;
  margin-left: 30px;
}

.carousel-indicators [data-bs-target] {
  background-color: #313131 !important;
}

.carousel-indicators {
  margin-bottom: -75px !important;
}

.carousel-control-next-icon {
  background-image: url("../../Assets/social-media/indicators.svg") !important;
  background-color: #fbfbfb;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 25px !important;
  background-position: 50% !important;
  background-size: 8px !important;
}

.carousel-control-prev-icon {
  background-image: url("../../Assets/social-media/indicators.svg") !important;
  background-color: #fbfbfb;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 25px !important;
  background-position: 50% !important;
  background-size: 8px !important;
  transform: rotate(180deg) !important;
}

.carousel-indicators [data-bs-target] {
  width: 8px !important;
  height: 8px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
  border-radius: 100% !important;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1 !important;
  margin-right: -87px !important;
  margin-left: -87px !important;
}

.parent-hover {
  position: relative;
}
#img1 {
  position: relative;
}
#img2 {
  position: absolute;
  right: 0;
}
#img2:hover {
  opacity: 0;
  transition: opacity 0.2s;
}

@media (max-width: 1050px) {
  .product-banner {
    margin-bottom: 0px;
    margin-left: 0px;
    background: url("../../Assets/banner-back-product-mob.jpg");
    background-size: contain;
    height: 250px;
    background-repeat: round;
  }
}

@media (min-width: 1400px) {
  .product-cont-categories {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 1400px) {
  .product-box-category {
    width: 470px;
  }
}

@media (max-width: 515px) {
  .product-hero {
    display: none;
  }
  .i-text-prod {
    display: none;
  }
  .product-cont-categories {
    min-width: auto;
    margin-right: 30px;
    margin-left: 30px;
  }
  .product-box-cont-categories {
    justify-content: center;
  }

  .product-box-category {
    width: 1000px;
    height: 450px;
  }
  .product-hero {
    min-width: min-content;
  }

  .product-subtitle {
    margin-right: 40px;
    margin-left: 40px;
  }
  .product-hero-cont {
    min-width: min-content;
  }

  .product-hero-mobile {
    margin-top: 50px;
    margin-bottom: 100px;
    margin-left: 30px;
    margin-right: 30px;
    height: 645px;
    min-width: min-content;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .product-banner-title {
    font-size: 34px;
    padding-top: 66px;
    margin-left: 105px;
    line-height: 44px;
  }
  .product-banner-subtitle {
    font-size: 16px;
    margin-left: 105px;
    padding-top: 10px;
  }

  .product-banner {
    padding-left: 20px;
  }
  .product-banner {
    background-repeat: round;
  }
}
@media (max-width: 430px) {
  .product-banner {
    background-repeat: no-repeat;
    padding-left: 0px;
  }
  .product-banner-title {
    margin-left: 90px;
  }
  .product-banner-subtitle {
    margin-left: 90px;
  }
}

.spinner-a {
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
