a {
  text-decoration: none !important;
}
svg {
  vertical-align: text-top !important;
}
.nav-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  height: 75px;
  min-width: 1125px;
}
.cta-nav {
  margin-left: 25px;
  background-color: #3c5ea9;
  color: white !important;
  padding: 10px 35px;
  text-decoration: none !important;
}

.left-nav-cont {
  display: flex;
  align-items: center;
}
.right-nav-cont {
  display: flex;
  align-items: center;
}
.alert-container {
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(30px);
  z-index: 100;
  min-width: 1200px;
}
.alert-inner {
  width: 100vw;
  margin-top: 40px;
  margin-bottom: 50px;
  padding-left: 140px;
  padding-right: 140px;
  min-width: 1200px;
}

.products-header {
  width: 345px;
  height: 90px;
  margin-right: 40px;
  margin-bottom: 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.sub-products-header {
  margin-top: -25px !important;
  margin-left: 30px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #313131;
}

.header-products-cont {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.title-products-header {
  margin-left: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #313131;
}

.products-header:hover .title-products-header {
  color: #3c5ea9;
  text-underline-offset: 5px;
  text-decoration: underline;
}
.title-products-header:hover {
  color: #3c5ea9;
}

.btn-search {
  background-color: #3c5ea9;
  width: 33px;
  height: 33px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-cont {
  display: flex;
  border: solid #dadada;
  padding: 4px;
  width: 222px;
  height: 44px;
}

.probando {
  position: absolute;
  /* margin-top: 50px; */
  background-color: white;
  z-index: 100;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
}

.search-product {
  width: 222px;
  height: 40px;
  border-bottom: 0.5px solid #dcdcdc;
  padding-top: 10px;
  padding-left: 10px;
}
.search-product:hover {
  /* background-color: rgba(128, 128, 128, 0.185); */
  background: #eff4ff;
}

.p-search {
  color: #313131;
}
.p-search:hover {
  color: #3c5ea9;
}

input {
  background: white;
  outline: none;
  border: none;
  color: black;
  font-size: 2.4rem;
}

@media (max-width: 1435px) {
  .alert-inner {
    padding-right: 0px;
  }
}

.header-title {
  cursor: pointer;
  color: #3c5ea9;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.footer-cont {
  display: flex;
  justify-content: space-between;
  margin-left: 100px;
  margin-right: 100px;
  padding-bottom: 100px;
  max-width: 1240px;
  min-width: 1000px;
}
.ul-footer {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #313131;
  list-style: none;
}

.title-footer {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #313131;
}

.li-footer {
  margin-top: 14px;
}

@media (max-width: 515px) {
  .header-title {
    display: none;
  }
  .right-nav-cont {
    display: none;
  }
  .hamburger-react {
    /* width: 20px !important; */
    /* cursor: pointer !important; */
    /* display: flex !important; */
    /* align-items: center !important; */
  }
}

@media (min-width: 515px) {
  .hamburger-react {
    display: none !important;
  }
}

.hamb-cont {
  width: 100vw;
  height: 90vh;
  background: rgba(32, 64, 134, 0.76);
  backdrop-filter: blur(20px);
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 90px;
}

.pruebaa {
  background-image: url("../Assets/hero-cont.png");
  height: 100vh;
}

.li-header-mob {
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  list-style: none;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
  color: #ffffff;
}

.search-mob {
  margin-bottom: 30px;
  border: none;
  background-color: white;
  padding: 4px;
  display: flex;
}
ul {
  padding-inline-start: 0px !important;
}

.btn-search-mob {
  background-color: #3c5ea9;
  width: 33px;
  height: 33px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-hr {
  margin-top: 150px;
  margin-bottom: 70px;
  min-width: 1200px;
}

@media (min-width: 1400px) {
  .footer-cont {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 1050px) {
  .footer-cont {
    margin-right: 35px;
    margin-left: 35px;
  }
}
@media (max-width: 650px) {
  .explorar {
    display: none;
  }
  .nav-cont {
    margin-right: 25px;
    margin-left: 25px;
  }
}

@media (max-width: 515px) {
  .nav-cont {
    min-width: min-content;
  }

  .footer-hr {
    min-width: min-content;
  }

  .footer-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 40px;
    min-width: min-content;
  }
  .explorar {
    display: block;
  }
  .ul-footer {
    margin-top: 40px;
  }
  .logo-footer {
    margin-top: 50px;
  }
  .redes {
    width: 80vw;
    display: flex;
    justify-content: space-between;
  }
  .title-redes {
    display: none;
  }
  /* .li-redes {
    margin-right: 40px;
  } */
}

.logo-footer {
  margin-bottom: 50px;
}
@media (max-height: 620px) {
  .li-header-mob {
    margin-bottom: 20px;
  }

  .hamb-cont {
    padding-top: 30px;
  }
}
