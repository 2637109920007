.hero-sub-cont {
  display: flex;
  align-items: flex-end;
}
.hero-left {
  width: 50vw;
}
.hero-right {
  width: 50vw;
}

.home-hero-cont {
  min-width: 1200px;
}

.carousel-caption {
  position: absolute;
  right: 0% !important;
  bottom: 0 !important;
  left: 0% !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #fff;
  text-align: center;
}

.hero1 {
  background-image: url("../../Assets/hero/desk_hero1.jpg");
  height: 90vh;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero2 {
  background-image: url("../../Assets/hero/desk_hero2.jpg");
  height: 90vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero3 {
  background-image: url("../../Assets/hero/desk_hero3.jpg");
  height: 90vh;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

#img-hero {
  height: 330px !important;
  width: 50vw !important;
}
.banner {
  height: 280px;
  width: 700px;
  background: url("../../Assets/prueba-01.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 50px;
}

.banner-title {
  font-size: 45px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: left;
  margin-left: 165px;
  line-height: 55px;
  padding-top: 40px;
  background: linear-gradient(99.82deg, #41b2d2 4.25%, #f0047f 84.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.banner-subtitle {
  font-size: 28px;
  color: black;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  margin-left: 165px;
  letter-spacing: 5px;
}

.home-cont-categories {
  padding-top: 100px;
  /* height: 470px; */
  margin-left: 100px;
  margin-right: 100px;
  max-width: 1240px;
  min-width: 1000px;
}
.box-cont-categories {
  display: flex;
  justify-content: space-between;
}

.box-category {
  width: 217px;
  height: 230px;
  border: solid #d9d9d9 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box-category:hover {
  background-color: #eff4ff;
  border: solid #3c5ea9 1px;
}
.title-categories {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #313131;
  padding-bottom: 40px;
}
.micro-banner-p {
  font-weight: 300;
}

.micro-banner-cont {
  margin-top: 100px;
  margin-bottom: 100px;
  height: 450px;
  border: solid 1px #d9d9d9;
  display: flex;
  margin-left: 100px;
  margin-right: 100px;
  max-width: 1240px;
  min-width: 1000px;
}

.cta-tienda {
  background-color: #3c5ea9;
  color: white !important;
  padding: 14px 42px;
  text-decoration: none !important;
}

.freezer-banner-cont {
  margin-top: 100px;
  margin-bottom: 100px;
  height: 420px;
  border: solid 1px #d9d9d9;
  display: flex;
  flex-direction: row-reverse;
  margin-left: 100px;
  margin-right: 100px;
  max-width: 1240px;
  min-width: 1000px;
}
.micro-banner-left {
  width: 50%;
  height: 100%;
  background-image: url("../../Assets/banner-inspiracional.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.micro-banner-right {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px;
  padding-left: 80px;
}

.micro-banner-title {
  font-size: 45px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: left;
  background: linear-gradient(99.82deg, #41b2d2 4.25%, #f0047f 84.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.cta-banner {
  margin-left: 25px;
  background-color: white;
  color: black !important;
  padding: 10px 35px;
  text-decoration: none !important;
}
.cta-banner-cont {
  margin-top: 25px;
}

.micro-banner-cont-hpt {
  margin-top: 100px;
  margin-bottom: 100px;
  height: 420px;
  margin-left: 100px;
  margin-right: 100px;
  background-image: url("../../Assets/banner-inspiracional-hpti.png");
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1240px;
  min-width: 1000px;
}

#freezer-img {
  background-image: url("../../Assets/banner-inspiracional-freezer.jpg");
}

.daewoo-banner-cont {
  margin-top: 100px;
  margin-bottom: 100px;
  height: 700px;
  display: flex;
  flex-direction: row-reverse;
  margin-left: 100px;
  margin-right: 100px;
  max-width: 1240px;
  min-width: 1000px;
}
.daewoo-banner-right {
  width: 50%;
  height: 100%;
  background-image: url("../../Assets/daewoo-marca.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.daewoo-banner-left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  padding-top: 100px;
}

.daewoo-banner-title {
  font-size: 45px;
  font-weight: 900;
  text-transform: uppercase;
  background: linear-gradient(
    93.76deg,
    #0b489d 8.48%,
    #072f66 26.13%,
    #0b489d 47.76%,
    #072f66 68.73%,
    #0b489d 97.32%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.daewoo-banner-p {
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #313131;
  font-weight: 700;
}
.daewoo-banner-title-mob {
  font-size: 45px;
  font-weight: 900;
  text-transform: uppercase;
  background: linear-gradient(
    93.76deg,
    #0b489d 8.48%,
    #072f66 26.13%,
    #0b489d 47.76%,
    #072f66 68.73%,
    #0b489d 97.32%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.daewoo-banner-p-mob {
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #313131;
  font-weight: 700;
}
.light {
  font-weight: 200;
  color: #313131;
}
.sq {
  width: 220px;
  height: 165px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.one {
  border-bottom: 1px #dadada solid;
  border-right: 1px #dadada solid;
}
.two {
  border-bottom: 1px #dadada solid;
}
.three {
  border-right: 1px #dadada solid;
}
.square {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  margin-left: -60px;
  min-width: 440px;
}
.daewoo-stats {
  font-size: 60px;
  font-weight: 900;

  text-align: left;
  background: linear-gradient(99.82deg, #41b2d2 4.25%, #f0047f 84.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.daewoo-stats-1 {
  font-size: 60px;
  font-weight: 900;
  background: linear-gradient(99.53deg, #41b2d2 37.52%, #f0047f 62.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.daewoo-stats-2 {
  font-size: 60px;
  font-weight: 900;
  background: linear-gradient(98deg, #41b2d2 51.09%, #f0047f 125.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.daewoo-stats-3 {
  font-size: 60px;
  font-weight: 900;

  background: linear-gradient(97.6deg, #41b2d2 34.73%, #f0047f 86.05%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.daewoo-stats-4 {
  font-size: 60px;
  font-weight: 900;
  background: linear-gradient(99.31deg, #41b2d2 16.28%, #f0047f 57.04%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.daewoo-stats-sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #313131;
}
.sq-aux {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#blabla {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  text-transform: capitalize;
  color: #d0d0d0;
  padding-right: 10px;
}

/* Banner inspiracional */
.pre-footer-back {
  margin-top: 100px;
  margin-bottom: 100px;
  height: 180px;
  margin-left: 100px;
  margin-right: 100px;
  background-color: #3c5ea9;
  max-width: 1240px;
  min-width: 1000px;
}

.pre-footer {
  background: linear-gradient(96.73deg, #41b3d28b 19.23%, #f0047e86 106.87%);
  height: 180px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1240px;
  min-width: 1000px;
}

.pre-footer-text {
  font-weight: 800;
  font-size: 32px;
  line-height: 41px;
  text-transform: uppercase;
  color: #ffffff;
}

.cta-pre-footer {
  background-color: white;

  height: 55px;
  color: #3c5ea9 !important;
  padding: 14px 35px;
  text-decoration: none !important;
}

img.crock {
  width: 145px;
}

@media (max-width: 1050px) {
  .hero-right {
    display: none;
  }
  .banner {
    margin-left: -60px;
    margin-bottom: 20px;
    /* background-image: url("../../Assets/banner-back-mob.jpg"); */
    /* background-size: contain; */
  }
}

@media (min-width: 1377px) {
  .br-1376 {
    display: none;
  }
}
@media (max-width: 515px) {
  .carousel-item {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .hero1 {
    height: 50vh !important;
    background-image: url("../../Assets/hero01.jpg");
    background-position: center;
  }
  .hero2 {
    height: 50vh !important;
    background-position: center;
  }
  .hero3 {
    height: 50vh !important;
    background-position: center;
  }
  .carousel-caption {
    height: auto !important;
    position: relative !important;
  }
  .banner {
    margin-bottom: 0px;
    margin-left: 0px;
    height: 313px;
    background-image: url("../../Assets/banner-back-mob.jpg");
    background-size: contain;
  }
  .banner-title {
    font-size: 34px;
    padding-top: 70px;
    margin-left: 105px;
    line-height: 44px;
  }
  .banner-subtitle {
    font-size: 16px;
    margin-left: 105px;
  }
}

@media (max-width: 430px) {
  .banner {
    height: 260px;
  }
  .banner-title {
    font-size: 34px;
    padding-top: 56px;
    margin-left: 90px;
    line-height: 44px;
  }
  .banner-subtitle {
    margin-left: 90px;
  }
}
@media (max-width: 400px) {
  .banner {
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .banner-title {
    font-size: 32px;
  }
}

@media (max-width: 374px) {
  .banner-title {
    font-size: 26px;
    letter-spacing: 0px;
  }
  .banner-subtitle {
    letter-spacing: 3px;
  }
}

@media (min-width: 1400px) {
  .home-cont-categories {
    margin-right: auto;
    margin-left: auto;
  }
  .micro-banner-cont {
    margin-right: auto;
    margin-left: auto;
  }
  .freezer-banner-cont {
    margin-right: auto;
    margin-left: auto;
  }
  .micro-banner-cont-hpt {
    margin-right: auto;
    margin-left: auto;
  }
  .daewoo-banner-cont {
    margin-right: auto;
    margin-left: auto;
  }
  .pre-footer {
    margin-right: auto;
    margin-left: auto;
  }
  .pre-footer-back {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 1300px) {
  .out {
    display: none;
  }
}

@media (max-width: 515px) {
  .out {
    display: flex;
  }
  .home-cont-categories {
    margin-right: 35px;
    margin-left: 35px;
  }
  .micro-banner-cont {
    margin-right: 35px;
    margin-left: 35px;
  }
  .freezer-banner-cont {
    margin-right: 35px;
    margin-left: 35px;
  }
  .micro-banner-cont-hpt {
    margin-right: 35px;
    margin-left: 35px;
  }
  .daewoo-banner-cont {
    margin-right: 35px;
    margin-left: 35px;
  }

  .pre-footer-back {
    margin-right: 35px;
    margin-left: 35px;
  }
}
@media (max-width: 1130px) {
  .micro-banner-left {
    background-size: cover;
    background-position: top right -40px;
  }
  .square {
    margin-top: 35px;
    margin-left: -48px;
  }
  .micro-banner-cont-hpt {
    background-size: cover;
    background-position: top left -96px;
  }
}

@media (min-width: 1380px) {
  .br-micro {
    display: none;
  }
}

/* MOBILE! */

@media (max-width: 515px) {
  .home-hero-cont {
    min-width: min-content;
  }
  .home-cont-categories {
    margin-left: 35px;
    margin-right: 35px;
    min-width: min-content;
    align-items: center;
  }
  .box-cont-categories {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .box-category {
    margin-top: 28px;
  }

  .box-category {
    width: 276px;
    height: 300px;
  }
  .category-img {
    width: 145px;
  }

  .freezer-banner-cont {
    display: flex;
    flex-direction: column;
    min-width: min-content;
    height: 650px;
  }
  .micro-banner-cont {
    display: flex;
    flex-direction: column;
    min-width: min-content;
    height: 650px;
  }
  .micro-banner-left {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 50%;
    height: 40%;
  }
  .micro-banner-right {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px;
    padding-left: 0px;
  }
  .micro-banner-sub {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #313131;
    padding-top: 30px;
    padding-bottom: 20px !important;
  }
  .micro-banner-title {
    font-size: 30px;
    line-height: 36px;
    padding-bottom: 20px !important;
    text-align: center;
  }
  .br-micro {
    display: block;
  }
  .micro-banner-p {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #313131;
    margin-bottom: 8px !important;
  }
  .cta-banner-cont {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    margin-bottom: 25px;
  }
  .cta-tienda {
    padding: 12px 76px;
  }
  .cta-banner {
    margin-left: 0px;
  }
  .br-banner-micro {
    display: none;
  }
  .micro-banner-cont-hpt {
    display: none;
  }
  .pre-footer {
    background: transparent;
    width: 100vw;
    min-width: min-content;
    margin: 0;
    height: 450px;
    display: flex;
    flex-direction: column;
  }
  .pre-footer-back {
    background: linear-gradient(114.23deg, #41b2d2 5.38%, #f0047f 105.06%);
    width: 100vw;
    min-width: min-content;
    margin: 0;
    width: 100vw;
    min-width: min-content;
    margin: 0;
    height: 450px;
  }

  .daewoo-banner-cont {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    min-width: min-content;
  }

  .daewoo-banner-right {
    background-image: url("../../Assets/daewoo-banner-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    width: 85vw;
  }
  .daewoo-banner-title-mob {
    font-size: 9vw;
  }
  .daewoo-banner-p-mob {
    font-size: 3.5vw;
    padding-bottom: 65px;
  }

  .daewoo-banner-left {
    padding: 0px;
    width: 100%;
    text-align: center;
    margin-right: 30px;
    margin-left: 30px;
    max-width: 200px;
  }
  .square {
    margin: 0px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    min-width: auto;
  }
  .sq {
    border: none;
    border-bottom: 1px #dadada solid;
  }
  .four {
    border: none;
  }
  .daewoo-banner-title {
    display: none;
  }
  .daewoo-banner-p {
    display: none;
  }
  img.crock {
    width: 225px;
  }
  .br-1376 {
    display: none;
  }
}

.micro-banner-cont-hpt-mob {
  margin-top: 100px;
  margin-bottom: 100px;
  height: 480px;
  margin-left: 30px;
  margin-right: 30px;
  min-width: min-content;
  background-image: url("../../Assets/banner-inspiracional-hpti-mob.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 515px) {
  .br-500 {
    display: none;
  }
  .micro-banner-cont-hpt-mob {
    display: none;
  }
  .daewoo-banner-title-mob {
    display: none;
  }
  .daewoo-banner-p-mob {
    display: none;
  }
}
